/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myselect-part {
  min-width: 200px;
}
.myselect-part-dropdown .part-sku,
.myselect-part .part-sku {
  color: #1890ff;
  font-weight: 600;
}
